import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import setAuthToken from '../../Utils/setAuthToken';
import { LOAD_PRODUCTS } from './products';

const inistialState = {
	token: localStorage.getItem('newishToken'),
	isAuthenticated: null,
	loading: true,
	user: null,
};

const auth = createSlice({
	name: 'auth',
	initialState: inistialState,
	reducers: {
		REGISTER_SUCCESS(state, action) {
			//get token and user from action payload
			const token = action.payload.token;
			const user = action.payload.user;

			localStorage.setItem('newishToken', token);
			setAuthToken(token);
			//update redux state
			return (state = { isAuthenticated: true, loading: false, token, user });
		},
		REGISTER_FAIL(state, action) {
			//you don't want a wrong token in local storage, always remove it
			localStorage.removeItem('newishToken');
			delete axios.defaults.headers.common['x-auth-newish-token'];

			//update redux state
			return (state = { token: null, isAuthenticated: false, loading: false });
		},
		LOGIN_SUCCESS(state, action) {
			//get token and user from action payload
			const token = action.payload.token;
			const user = action.payload.user;

			localStorage.setItem('newishToken', token);

			setAuthToken(token);
			//update redux state
			return (state = { isAuthenticated: true, loading: false, token, user });
		},
		LOGIN_FAIL(state, action) {
			//you don't want a wrong token in local storage, always remove it
			localStorage.removeItem('newishToken');
			delete axios.defaults.headers.common['x-auth-newish-token'];

			//update redux state
			return (state = { token: null, isAuthenticated: false, loading: false });
		},
		LOGOUT(state, action) {
			localStorage.removeItem('newishToken');
			delete axios.defaults.headers.common['x-auth-newish-token'];

			return (state = {
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
			});
		},
		USER_LOADING(state, action) {
			return (state = {
				...state,
				loading: true,
			});
		},
		USER_LOADED(state, action) {
			return (state = {
				user: action.payload,
				isAuthenticated: true,
				loading: false,
			});
		},
		USER_ERROR(state, action) {
			localStorage.removeItem('newishToken');
			const user = state.user;
			return (state = {
				isAuthenticated: false,
				loading: false,
				user,
			});
		},

		AUTH_ERROR(state, action) {
			localStorage.removeItem('newishToken');
			return (state = { token: null, isAuthenticated: false, loading: false });
		},
	},
});

export const authActions = auth.actions;
export const REGISTER_SUCCESS = auth.actions.REGISTER_SUCCESS;
export const REGISTER_FAIL = auth.actions.REGISTER_FAIL;
export const LOGIN_SUCCESS = auth.actions.LOGIN_SUCCESS;
export const LOGIN_FAIL = auth.actions.LOGIN_FAIL;
export const LOGOUT = auth.actions.LOGOUT;
export const USER_LOADED = auth.actions.USER_LOADED;
export const AUTH_ERROR = auth.actions.AUTH_ERROR;
export const USER_LOADING = auth.actions.USER_LOADING;
export const USER_ERROR = auth.actions.USER_ERROR;

export const LOAD_USER = () => async (dispatch) => {
	if (localStorage.newishToken) {
		setAuthToken(localStorage.newishToken);
	} else {
		dispatch(USER_ERROR());
		return;
	}
	dispatch(USER_LOADING());

	try {
		await axios
			.get(`${process.env.REACT_APP_SERVER_API}/api/auth`)
			.then((res) => {
				console.log(res.data);
				dispatch(USER_LOADED(res.data));
				dispatch(LOAD_PRODUCTS());
			})
			.catch((err) => {
				dispatch(USER_ERROR());
			});
	} catch (error) {
		dispatch(USER_ERROR());
	}
};

export default auth;
