import classes from "../Marketplaces.module.css";

const FirstSection = (props) => {
  const { title, paragraphs, gridItems } = props;
  return (
    <>
      <section className={classes.centerSection}>
        <h3 className={classes.title}>{title}</h3>

        <section className={classes.sectionParagraph}>
          {paragraphs?.map((paragraph, index) => (
            <p className={classes.description} key={index}>
              {paragraph}
            </p>
          ))}
        </section>
      </section>
      <section className={classes.grid}>
        {gridItems.map((item, index) => (
          <section className={classes.gridItem} key={index}>
            <div className={classes.iconContainer}>{item.icon}</div>
            <h4 className={classes.gridTitle}>{item.title}</h4>
            <p className={classes.gridParagraph}>{item.paragraph}</p>
          </section>
        ))}
      </section>
    </>
  );
};
export default FirstSection;
