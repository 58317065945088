import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inistialState = {
	loading: true,
	search: '',
	products: null,
	loadingProducts: [],
	paginationData: null,
};

const products = createSlice({
	name: 'products',
	initialState: inistialState,
	reducers: {
		PRODUCTS_LOADING(state, action) {
			return (state = {
				...state,
				loading: true,
				search: action.payload?.search,
			});
		},
		PRODUCTS_LOADED(state, action) {
			console.log('state.loadingProducts ', state.loadingProducts);
			return (state = {
				...state,
				products: action.payload.products,
				paginationData: action.payload.paginationData,
				loading: false,
				search: '',
			});
		},
		PRODUCTS_ERROR(state, action) {
			return (state = {
				...state,
				loading: false,
				search: '',
			});
		},
		UPDATE_LOADING_PRODUCTS(state, action) {
			return (state = {
				...state,
				loadingProducts: action.payload,
			});
		},
	},
});

export const PRODUCTS_LOADED = products.actions.PRODUCTS_LOADED;
export const PRODUCTS_LOADING = products.actions.PRODUCTS_LOADING;
export const PRODUCTS_ERROR = products.actions.PRODUCTS_ERROR;
export const UPDATE_LOADING_PRODUCTS = products.actions.UPDATE_LOADING_PRODUCTS;

export const LOAD_PRODUCTS = () => async (dispatch, getState) => {
	const { products } = getState();

	const searchParams = new URLSearchParams(window.location.search);

	const filters = searchParams.toString();
	let url = `${process.env.REACT_APP_SERVER_API}/api/products/page`;
	if (filters) {
		url = `${url}?${filters}`;
	}

	console.log(products);
	console.log(products.loading && products.search === url);
	if (products.loading && products.search === url) return;

	dispatch(PRODUCTS_LOADING({ search: url }));
	await axios
		.get(`${url}`)
		.then((res) => {
			console.log(res.data);
			dispatch(PRODUCTS_LOADED(res.data));
		})
		.catch((err) => {
			console.log(err);
			dispatch(PRODUCTS_ERROR());
		});
};

export default products;
