import Marketplaces from "../components/MarketplacesPage/Marketplaces";

const MarketplacesPage = () => {
  return (
    <>
      <Marketplaces />
    </>
  );
};
export default MarketplacesPage;
