import classes from "./PrimaryButton.module.css";

const PrimaryButton = (props) => {
  let round = props.round ? classes.round : "";
  let color = props.color ? classes[props.color] : "";
  let size = props.size ? classes[props.size] : "";
  let available =
    props.available === false ? classes.unavailable : classes.available;

  return (
    <button
      type={props.type}
      ref={props.ref}
      onClickCapture={props.onClickCapture}
      className={`${classes.button} ${round} ${color} ${size} ${available} `}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
