export const platforms = [
	{
		name: 'Facebook',
		available: false,
		export: false,
		id: 'facebook',
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/facebook.png',
	},

	{
		name: 'Etsy',
		available: true,
		id: 'etsy',
		export: true,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/etsy.png',
	},
	{
		name: 'Chairish',
		available: true,
		id: 'chairish',
		export: true,
		import: true,
		// img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/chairishlogo.png',
		img: 'https://storage.googleapis.com/kashew_static/chairish-logo-red%403x.png',
	},
	{
		name: '1stDibs',
		available: false,
		id: '1stdibs',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/1stdibs.png',
	},
	{
		name: 'Kashew',
		available: true,
		id: 'kashew',
		export: true,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/kashew.png',
	},
	{
		name: 'AptDeco',
		available: false,
		id: 'aptdeco',

		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/aptdecologo.png',
	},

	{
		name: 'Ebay',
		available: true,
		id: 'ebay',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/ebay.webp',
	},

	{
		name: 'Craigslist',
		available: false,
		id: 'craigslist',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/craigslist.png',
	},
	{
		name: 'OfferUp',
		available: false,
		id: 'offerup',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/offerup.png',
	},
	{
		name: 'Mercari',
		available: false,
		id: 'mercari',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/mercari.png',
	},

	{
		name: 'Shopify',
		available: true,
		id: 'shopify',
		export: true,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/shopify.png',
	},

	{
		name: 'Woocommerce',
		available: true,
		id: 'woocommerce',
		export: true,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/woocommerce.png',
	},

	// { name: 'Google', available: false, export: false, import: true },
	// { name: 'Squarespace', available: false, export: false, import: true },
	{
		name: 'X-cart',
		available: false,
		id: 'xcart',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/xcart.png',
	},
	// { name: 'Wix', available: false, export: false, import: true },
	// { name: 'WordPress', available: false, export: false, import: true },
	// { name: 'BigCommerce', available: false, export: false, import: true },
	// { name: 'Ecwid', available: false, export: false, import: true },
	// { name: 'Instagram', available: false, export: false, import: true },
	// { name: 'GoDaddy', available: false, export: false, import: true },
	// { name: 'Attic', available: false, export: false, import: true },
	// { name: 'Amazon', available: false, export: false, import: true },

	{
		name: 'CSV',
		available: true,
		id: 'csv',
		export: false,
		import: true,
		img: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/excel.png',
	},
];
