import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import Loader from '../components/UI/Loader.js';

const PrivateRoute = (props) => {
	const history = useHistory();

	const auth = useSelector((state) => state.auth);

	return (
		<>
			<Route {...props}>
				{auth.loading ? (
					<Loader />
				) : auth.isAuthenticated ? (
					<>{props.children}</>
				) : !auth.isAuthenticated && !auth.loading ? (
					history.push('/login')
				) : !auth.isAuthenticated && auth.loading ? (
					<Loader />
				) : (
					''
				)}
			</Route>
		</>
	);
};

export default PrivateRoute;
