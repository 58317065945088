import { GoPeople, GoMegaphone } from "react-icons/go";
import Benefits from "./Components/Benefits";
import FirstSection from "./Components/FirstSection";
import Hero from "./Components/Hero";
import classes from "./Marketplaces.module.css";
import { LuArmchair } from "react-icons/lu";
import { PiMoney } from "react-icons/pi";

const Shopify = () => {
  const heroInfo = {
    title: "Grow Your Business with Shopify",
    subtitle: "How to sell furniture on Shopify",
    paragraph:
      "Grow your business, your brand, and your source of income with your own e-commerce store on Shopify. ",
    logo: "https://storage.googleapis.com/kashew_static/Shopify-Logo-1.png",
  };

  const firstInfo = {
    title: "Why resell furniture on Shopify?",
    paragraphs: [
      "Shopify is an all-inclusive and easy-to-use e-commerce website builder that sells as a subscription service. It includes many tools to help you run an online store, providing a solution for marketing products and processing payments. You don't need to be a web designer to make a professional website.",
    ],
  };

  const gridItems = [
    {
      title: "Financial Benefits",
      paragraph:
        "Shopify offers various pricing plans that fit your business needs, allowing you to maximize your profits with transparent and straightforward fees.",
      icon: <PiMoney className={classes.icon} />,
    },
    {
      title: "Customization",
      paragraph:
        "Shopify provides a variety of customizable themes and design options to make your store look professional and unique, helping you attract more buyers.",
      icon: <LuArmchair className={classes.icon} />,
    },
    {
      title: "Marketing Tools",
      paragraph:
        "Shopify offers a suite of marketing tools, including SEO optimization, social media integrations, and email marketing, to help you reach a wider audience and drive sales.",
      icon: <GoMegaphone className={classes.icon} />,
    },
    {
      title: "Integrated Payments",
      paragraph:
        "Shopify's built-in payment gateway supports various payment methods, providing a seamless and secure checkout experience for your customers.",
      icon: <GoPeople className={classes.icon} />,
    },
  ];

  return (
    <section className={classes.main}>
      <Hero
        title={heroInfo.title}
        subtitle={heroInfo.subtitle}
        paragraph={heroInfo.paragraph}
        logo={heroInfo.logo}
      />

      <section className={classes.maxWidth}>
        <FirstSection
          title={firstInfo.title}
          paragraphs={firstInfo.paragraphs}
          gridItems={gridItems}
        />
        <Benefits company="Shopify" />
      </section>
    </section>
  );
};
export default Shopify;
