import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Hero from "./Components/Hero";
import MarketplacesList from "./Components/MarketplacesList";
import Chairish from "./Marketplaces/Chairish";
import Kashew from "./Marketplaces/Kashew";
import Etsy from "./Marketplaces/Etsy";
import Ebay from "./Marketplaces/Ebay";
import Shopify from "./Marketplaces/Shopify";
import WooCommerce from "./Marketplaces/WooCommerce";
import MarketplaceHelmets from "../../Hooks/MarketplaceHelmets";

const Marketplaces = () => {
  const { marketplace } = useParams();

  console.log(marketplace, "marketplace");

  const marketplaceHandler = () => {
    switch (marketplace) {
      case "chairish":
        return <Chairish />;
      case "kashew":
        return <Kashew />;
      case "etsy":
        return <Etsy />;
      case "ebay":
        return <Ebay />;
      case "shopify":
        return <Shopify />;
      case "woocommerce":
        return <WooCommerce />;
      default:
        return (
          <>
            <Hero /> <MarketplacesList />
          </>
        );
    }
  };

  return (
    <>
      <MarketplaceHelmets marketplace={marketplace} />
      {marketplaceHandler()}
    </>
  );
};

export default Marketplaces;
