import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <>
      <div className={classes.loadingScreen}>
        <img
          src="https://ik.imagekit.io/kuj9zqlrx0or/Static/Interwind-1s-200px.gif"
          style={{ width: "100px", height: "100px" }}
          alt="loader"
        />
      </div>
    </>
  );
};
export default Loader;
