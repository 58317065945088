import { motion } from "framer-motion";
import { useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { REMOVE_ALERT } from "../../Redux/Reducers/alert";
import classes from "./Alert.module.css";

const Alert = () => {
  const alerts = useSelector((state) => state.alert);

  const dispatch = useDispatch();

  useEffect(() => {
    if (alerts !== null && alerts.length > 0) {
      alerts.map((alert) => {
        setTimeout(() => dispatch(REMOVE_ALERT(alert.id)), 6500);
      });
    }
  }, [alerts]);

  const closeAlert = (id) => dispatch(REMOVE_ALERT(id));

  return (
    <>
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map((alert) => (
          <motion.div
            initial={{ y: "100vh" }}
            animate={{ y: 0 }}
            exit={{ y: "100vh" }}
            transition={{
              duration: 0.6,
            }}
            className={classes.fixed}
          >
            <div
              className={
                alert.type == "danger" ? classes.alertDanger : classes.alert
              }
            >
              <p className={classes.msg}>{alert.msg}</p>

              <AiOutlineCloseCircle
                className={classes.icon}
                onClick={() => closeAlert(alert.id)}
              />
              {alert && alert.type === "danger" && (
                <div className={classes.progressBar}>
                  <div className={classes.progress} />
                </div>
              )}
              {alert && alert.type === "success" && (
                <div className={classes.progressBarSuccess}>
                  <div className={classes.progressSuccess} />
                </div>
              )}
            </div>
          </motion.div>
        ))}
    </>
  );
};

export default Alert;
