import classes from "./NotFound.module.css";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { platforms } from "../../../Utils/importPlatforms";
import PrimaryButton from "../../UI/Buttons/PrimaryButton";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  let plat = platforms.filter(
    (platform) => platform.name !== "CSV" && platform.available
  );

  const history = useHistory();

  const radius = 150;

  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const platformsInterval = setInterval(() => {
      setAngle((prevAngle) => (prevAngle + Math.PI / 180) % (2 * Math.PI));
    }, 50);

    return () => {
      clearInterval(platformsInterval);
    };
  }, []);
  return (
    <div className={classes.main}>
      <section className={classes.leftSection}>
        <h1 className={classes.header}>
          <span className={classes.underline}>Whoops!</span>
        </h1>
        <h2 className={classes.subHeader}>Page Not Found</h2>
        <section className={classes.buttons}>
          <PrimaryButton onClick={() => history.push("/")}>
            Go Back
          </PrimaryButton>
        </section>
      </section>
      {/* <section className={classes.errorContainer}> */}
      <section className={classes.rightSection}>
        <img
          className={classes.logoContainer}
          src="https://ik.imagekit.io/kuj9zqlrx0or/Static/about_us/hero2.jpg?tr=w-300"
        ></img>

        <motion.svg width="100%" height="100%" className={classes.svgContainer}>
          <circle
            cx="50%"
            cy="50%"
            r={radius}
            className={classes.dashedCircle}
          />
        </motion.svg>

        {plat?.map((platform, index) => {
          // Calculate x and y based on circle math including the angle for continuous rotation
          const angleStep = (2 * Math.PI) / plat.length;
          const x = Math.cos(angleStep * index + angle) * radius;
          const y = Math.sin(angleStep * index + angle) * radius;

          return (
            <motion.div
              key={index}
              className={classes.platform}
              initial={{ x: 0, y: 0 }}
              animate={{ x: x, y: y }}
              transition={{ duration: 0.5, ease: "linear" }}
            >
              <img
                src={platform.img}
                alt={platform.name}
                className={classes.imgPlatform}
              />
            </motion.div>
          );
        })}
      </section>
    </div>
  );
};
export default NotFound;
