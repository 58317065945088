import { GoMegaphone, GoPeople } from "react-icons/go";
import Benefits from "./Components/Benefits";
import FirstSection from "./Components/FirstSection";
import Hero from "./Components/Hero";
import classes from "./Marketplaces.module.css";
import { LuArmchair } from "react-icons/lu";
import { PiMoney } from "react-icons/pi";

const Ebay = () => {
  const heroInfo = {
    title: "Free eBay Crosslister",
    subtitle: "How to sell furniture on eBay?",
    paragraph:
      "eBay is a leading marketplace for used items. What makes this marketplace so popular are its auctions and the wide variety of products that can be found in it.",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/ebay.webp",
  };

  const firstInfo = {
    title: "Why resellers need an eBay shop",
    paragraphs: [
      "Over 19 million active sellers use eBay, showcasing a wide range of items to over 182 million active buyers.",
      "eBay is a great platform to sell your preloved furniture, offering a robust marketplace with millions of potential customers in the U.S.",
    ],
  };

  const gridItems = [
    {
      title: "Financial Benefits",
      paragraph:
        "With eBay's competitive fee structure, you keep the majority of your earnings, maximizing your profits with every sale.",
      icon: <PiMoney className={classes.icon} />,
    },
    {
      title: "Marketing Tools",
      paragraph:
        "eBay offers a variety of marketing tools, including promoted listings and social media integrations, to help you reach more buyers.",
      icon: <GoMegaphone className={classes.icon} />,
    },
    {
      title: "Optimized Listings",
      paragraph:
        "eBay listings are optimized for SEO, making them easily discoverable across the web.",
      icon: <LuArmchair className={classes.icon} />,
    },
    {
      title: "Reach",
      paragraph:
        "eBay connects you with a large community of U.S. buyers who appreciate unique furniture, exposing your listings to millions of potential customers.",
      icon: <GoPeople className={classes.icon} />,
    },
  ];

  return (
    <section className={classes.main}>
      <Hero
        title={heroInfo.title}
        subtitle={heroInfo.subtitle}
        paragraph={heroInfo.paragraph}
        logo={heroInfo.logo}
      />

      <section className={classes.maxWidth}>
        <FirstSection
          title={firstInfo.title}
          paragraphs={firstInfo.paragraphs}
          gridItems={gridItems}
        />
        <Benefits company="eBay" />
      </section>
    </section>
  );
};
export default Ebay;
