import { GoPeople } from "react-icons/go";
import Benefits from "./Components/Benefits";
import FirstSection from "./Components/FirstSection";
import Hero from "./Components/Hero";
import classes from "./Marketplaces.module.css";
import { LuArmchair } from "react-icons/lu";
import { CiDeliveryTruck } from "react-icons/ci";
import { PiMoney } from "react-icons/pi";

const Chairish = () => {
  const heroInfo = {
    title: "Free Chairish Crosslister",
    subtitle: "How to sell furniture on Chairish? ",
    paragraph:
      "Chairish is one of the largest and most beloved online marketplaces for vintage and antique furniture. If you have a substantial inventory, don't miss the opportunity to reach more buyers by selling on Chairish.",
    logo: "https://storage.googleapis.com/kashew_static/chairish-logo-red%403x.png",
  };

  const firstInfo = {
    title: "Why resellers need a Chairish shop",
    paragraphs: [
      "Over 10,000 small U.S. businesses already sell on Chairish, offering more than 850,000 products to around 4 million monthly shoppers.",
    ],
  };

  const gridItems = [
    {
      title: "Financial Benefits",
      paragraph:
        "Depending on your Seller Plan, 55% to 80% of the selling price comes back to you.",
      icon: <PiMoney className={classes.icon} />,
    },
    {
      title: "Shipping",
      paragraph:
        "Chairish offers a white-glove shipping service for large items, and a standard shipping service for smaller items.",
      icon: <CiDeliveryTruck className={classes.icon} />,
    },
    {
      title: "Professional Listings",
      paragraph:
        "Chairish will make your listings look professional, with whited-out main images, and minor updates to your titles and descriptions to give your item as much traction as possible with their audience.",
      icon: <LuArmchair className={classes.icon} />,
    },
    {
      title: "Reach",
      paragraph:
        "Chairish attracts design-oriented buyers who will appreciate your items & millions of shoppers are exposed to your listings each month",
      icon: <GoPeople className={classes.icon} />,
    },
  ];

  return (
    <section className={classes.main}>
      <Hero
        title={heroInfo.title}
        subtitle={heroInfo.subtitle}
        paragraph={heroInfo.paragraph}
        logo={heroInfo.logo}
      />

      <section className={classes.maxWidth}>
        <FirstSection
          title={firstInfo.title}
          paragraphs={firstInfo.paragraphs}
          gridItems={gridItems}
        />
        <Benefits company="Chairish" />
      </section>
    </section>
  );
};
export default Chairish;
