import Privacy from "../components/LegalAndUtilPages/LegalPages/Privacy";

const PrivacyPage = () => {
  return (
    <>
      <Privacy />
    </>
  );
};
export default PrivacyPage;
