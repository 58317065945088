import classes from "./Legal.module.css";

const Privacy = () => {
  return (
    <section className={classes.main}>
      <h1>Privacy Policy</h1>

      <p className={classes.lastUpdated}>Last Updated: 10/03/2024</p>

      <p>
        Thank you for choosing to be part of our community at Newish, a service
        provided by Kashew Corp. (“Company”, “we”, “us”, or “our”). We are
        committed to protecting your personal information and your right to
        privacy. If you have any questions or concerns about this privacy
        policy, or our practices with regards to your personal information,
        please contact us at support@kashew.com.
      </p>

      <p>
        When you visit our website newish.ai (the "Site") and use our services,
        you trust us with your personal information. We take your privacy very
        seriously. In this privacy policy, we seek to explain to you in the
        clearest way possible what information we collect, how we use it, and
        what rights you have in relation to it. If there are any terms in this
        privacy policy that you do not agree with, please discontinue use of our
        Site and our services.
      </p>

      <h2>What information do we collect?</h2>

      <p>
        <strong>Personal information you disclose to us:</strong> We collect
        personal information that you voluntarily provide to us when registering
        at the Site, expressing an interest in obtaining information about us or
        our products and services, when participating in activities on the Site
        or otherwise contacting us.
      </p>

      <p>
        The personal information we collect might include your name, address,
        contact information, passwords and security data, and payment
        information.
      </p>

      <h2>How do we use your information?</h2>

      <p>
        We use the information we collect or receive to facilitate account
        creation and logon process, fulfill and manage your orders, protect our
        Services, enforce our terms, conditions, and policies, and respond to
        legal requests and prevent harm.
      </p>

      <h2>Will your information be shared with anyone?</h2>

      <p>
        We only share information with your consent, to comply with laws, to
        provide you with services, to protect your rights, or to fulfill
        business obligations.
      </p>

      <h2>Do we use cookies and other tracking technologies?</h2>

      <p>
        We may use cookies and similar tracking technologies to collect and
        store your information.
      </p>

      <h2>How long do we keep your information?</h2>

      <p>
        We keep your information for as long as necessary to fulfill the
        purposes outlined in this privacy policy unless otherwise required by
        law.
      </p>

      <h2>How do we keep your information safe?</h2>

      <p>
        We aim to protect your personal information through a system of
        organizational and technical security measures.
      </p>

      <h2>Do we collect information from minors?</h2>

      <p>
        We do not knowingly collect data from or market to children under 18
        years of age.
      </p>

      <h2>What are your privacy rights?</h2>

      <p>You may review, change, or terminate your account at any time.</p>

      <h2>Controls for do-not-track features</h2>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (“DNT”) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected.
      </p>

      <h2>Do California residents have specific privacy rights?</h2>

      <p>
        Yes, if you are a resident of California, you are granted specific
        rights regarding access to your personal information.
      </p>

      <h2>Do we make updates to this policy?</h2>

      <p>
        We may update this privacy policy from time to time in order to stay
        compliant with relevant laws.
      </p>

      <h2>How can you contact us about this policy?</h2>

      <p>
        If you have questions or comments about this policy, you may email us at
        support@kashew.com
      </p>
    </section>
  );
};
export default Privacy;
