import axios from 'axios';

export const errorSlackAlert = async (error) => {
	console.log('ININI');
	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/auth/slack-client-bug`, {
			error,
		})
		.catch((err) => {
			console.log(err);
		});
};
