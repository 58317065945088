import { LuArmchair } from "react-icons/lu";
import classes from "../Marketplaces.module.css";
import { RxUpdate } from "react-icons/rx";
import { PiMoney } from "react-icons/pi";
import PrimaryButton from "../../../UI/Buttons/PrimaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Benefits = (props) => {
  const { company } = props;

  const benefits = [
    {
      title: "Crosslister",
      paragraph: `In Newish, you can quickly take all the inventory you’re already selling and crosslist it easily into ${company}.`,
      icon: <LuArmchair className={classes.icon} />,
    },
    {
      title: "Bulk Updates",
      paragraph:
        "You can bulk update and delist products across all accounts with one click.",
      icon: <RxUpdate className={classes.icon} />,
    },
    {
      title: "Data Enrichment",
      paragraph: `Newish enriches your listing data to automatically fill out all the fields required. Bulk crosslist your items to ${company} in seconds.`,
      icon: <PiMoney className={classes.icon} />,
    },
  ];

  const history = useHistory();

  const onClick = () => {
    history.push("/register");
  };

  return (
    <>
      <section className={classes.sectionPadded}>
        <section className={classes.leftSectionGap}>
          <section className={classes.headerSmall}>
            <h3 className={classes.title}>
              The benefits of selling on {company} with Newish
            </h3>
            <p className={classes.muteText}>
              All you need 100% Free. No credit card required.
            </p>
          </section>
          <section className={classes.benefitsGrid}>
            {benefits.map((benefit, index) => (
              <section className={classes.gridItem} key={index}>
                <div className={classes.iconContainer}>{benefit.icon}</div>
                <h4 className={classes.gridTitle}>{benefit.title}</h4>
                <p className={classes.gridParagraph}>{benefit.paragraph}</p>
              </section>
            ))}
          </section>
        </section>
        <section className={classes.rightSection}>
          <img
            src="https://storage.googleapis.com/kashew_static/reseller_dream.png"
            className={classes.dream}
            alt={company}
          />
        </section>
      </section>
      <section className={classes.callToAction}>
        <PrimaryButton onClick={() => onClick()}>
          Get Started For Free
        </PrimaryButton>
      </section>{" "}
    </>
  );
};

export default Benefits;
