import FirstSection from "./Components/FirstSection";
import Hero from "./Components/Hero";
import classes from "./Marketplaces.module.css";
import { PiMoney } from "react-icons/pi";
import { CiDeliveryTruck } from "react-icons/ci";
import { GoPeople } from "react-icons/go";
import { LuArmchair } from "react-icons/lu";

import Benefits from "./Components/Benefits";

const Kashew = () => {
  const heroInfo = {
    title: "Free Kashew Crosslister",
    subtitle: "How to sell furniture on Kashew?",
    paragraph:
      "Kashew is the top marketplace for high-quality used furniture, offering local and nationwide insured delivery. Cross-list on Kashew.com to reach a large audience of shoppers nationwide looking for unique, preloved pieces.",
    logo: "https://storage.googleapis.com/kashew_static/logo-purple.png",
  };

  const firstInfo = {
    title: "Why resellers need a Kashew shop",
    paragraphs: [
      "On average, Kashew sellers increase their revenue by 20% in their first month of selling.",
      "They offer local and nationwide insured delivery, making it easy for buyers to purchase from you, no matter where they are locatedy.",
    ],
  };

  const gridItems = [
    {
      title: "More buyers",
      paragraph:
        "Kashew has 100,000s of shoppers across the nation, looking for unique, preloved pieces.",
      img: "https://storage.googleapis.com/kashew_static/more_buyers.png",
      icon: <GoPeople className={classes.icon} />,
    },
    {
      title: "Insured delivery",
      paragraph:
        "Kashew offers local and nationwide insured delivery, making it easy for buyers to purchase from you, no matter where they are located.",

      icon: <CiDeliveryTruck className={classes.icon} />,
    },
    {
      title: "Increased revenue",
      paragraph:
        "On average, Kashew sellers increase their revenue by 20% in their first month of selling.",
      icon: <PiMoney className={classes.icon} />,
    },
    {
      title: "Bulk Uploads",
      paragraph:
        "Kashew is the only marketplace that allows you to bulk upload your listings from all marketplaces, making listing enw items a breeze.",
      icon: <LuArmchair className={classes.icon} />,
    },
  ];

  return (
    <section className={classes.main}>
      <Hero
        title={heroInfo.title}
        subtitle={heroInfo.subtitle}
        paragraph={heroInfo.paragraph}
        logo={heroInfo.logo}
      />

      <section className={classes.maxWidth}>
        <FirstSection
          title={firstInfo.title}
          paragraphs={firstInfo.paragraphs}
          gridItems={gridItems}
        />

        <Benefits company="Kashew" />
      </section>
    </section>
  );
};
export default Kashew;
