import React from 'react';
import { errorSlackAlert } from './errorSlackAlert';

export default class StandardErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		console.log('IN1');
		errorSlackAlert({
			// message: error.toString(),
			stack: error.stack,
			componentStack: info.componentStack,
			info,
			error,
			location: window.location.href,
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback;
		}

		return this.props.children;
	}
}
