import { GoPeople, GoMegaphone } from "react-icons/go";
import Benefits from "./Components/Benefits";
import FirstSection from "./Components/FirstSection";
import Hero from "./Components/Hero";
import classes from "./Marketplaces.module.css";
import { LuArmchair } from "react-icons/lu";
import { PiMoney } from "react-icons/pi";

const WooCommerce = () => {
  const heroInfo = {
    title: "Grow Your Business with WooCommerce",
    subtitle: "How to sell furniture on WooCommerce",
    paragraph:
      "Grow your business, your brand, and your source of income with your own e-commerce store on WooCommerce.",
    logo: "https://storage.googleapis.com/kashew_static/Woocommerce_logo.png",
  };

  const firstInfo = {
    title: "Why resell furniture on WooCommerce?",
    paragraphs: [
      "WooCommerce is a flexible and powerful e-commerce plugin for WordPress, offering extensive customization options and a wide range of tools to help you manage and grow your online store.",
    ],
  };

  const gridItems = [
    {
      title: "Financial Benefits",
      paragraph:
        "WooCommerce is open-source and free to use, allowing you to keep more of your profits. Additional paid features can be added as your business grows.",
      icon: <PiMoney className={classes.icon} />,
    },
    {
      title: "Customization",
      paragraph:
        "WooCommerce provides a variety of customizable themes and design options to make your store look professional and unique, helping you attract more buyers.",
      icon: <LuArmchair className={classes.icon} />,
    },
    {
      title: "Marketing Tools",
      paragraph:
        "WooCommerce offers a suite of marketing tools, including SEO optimization, social media integrations, and email marketing, to help you reach a wider audience and drive sales.",
      icon: <GoMegaphone className={classes.icon} />,
    },
    {
      title: "Integrated Payments",
      paragraph:
        "WooCommerce supports a wide range of payment gateways, providing a seamless and secure checkout experience for your customers.",
      icon: <GoPeople className={classes.icon} />,
    },
  ];

  return (
    <section className={classes.main}>
      <Hero
        title={heroInfo.title}
        subtitle={heroInfo.subtitle}
        paragraph={heroInfo.paragraph}
        logo={heroInfo.logo}
      />

      <section className={classes.maxWidth}>
        <FirstSection
          title={firstInfo.title}
          paragraphs={firstInfo.paragraphs}
          gridItems={gridItems}
        />
        <Benefits company="WooCommerce" />
      </section>
    </section>
  );
};
export default WooCommerce;
