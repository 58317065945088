import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PrimaryButton from "../../../UI/Buttons/PrimaryButton";
import classes from "../Marketplaces.module.css";

const Hero = (props) => {
  const { title, subtitle, paragraph, logo } = props;

  const history = useHistory();

  const onClick = () => {
    history.push("/register");
  };

  return (
    <section className={classes.row}>
      <section className={classes.leftSection}>
        <section className={classes.header}>
          <h2 className={classes.underline}>{title}</h2>
          <h1 className={classes.gradient}>{subtitle}</h1>
        </section>
        <p className={classes.subHeader}>{paragraph}</p>
        <section className={classes.buttonContainer}>
          <PrimaryButton onClick={() => onClick()}>
            Start Crosslisting For Free
          </PrimaryButton>
        </section>
      </section>
      <section className={classes.rightSection}>
        <img src={logo} className={classes.logo} alt={title} />
      </section>
    </section>
  );
};
export default Hero;
