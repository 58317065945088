//Redux store
import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import auth from './Reducers/auth';
import alert from './Reducers/alert';
import products from './Reducers/products';
import schemas from './Reducers/schemas';

// console.log(activeChats);
const store = configureStore(
	{
		reducer: {
			auth: auth.reducer,
			alert: alert.reducer,
			products: products.reducer,
			schemas: schemas.reducer,
		},
		devTools: process.env.NODE_ENV !== 'production',
	},

	applyMiddleware(thunk)
);

export default store;
