import PrimaryButton from "../../UI/Buttons/PrimaryButton";
import classes from "./Hero.module.css";

import { useHistory } from "react-router-dom";

const Hero = () => {
  const history = useHistory();

  const handleClick = (link) => {
    history.push(link);
  };
  return (
    <>
      <section className={classes.heroContainer}>
        <section className={classes.row}>
          <section className={classes.leftSection}>
            <h1 className={classes.header}>
              Sell on all <br></br>Furniture Marketplaces <br /> With Newish!
            </h1>
            <h2 className={classes.subHeader}>
              Its free, easy to use and you can start selling in minutes.
            </h2>
            <section className={classes.buttonContainer}>
              <PrimaryButton
                onClick={() => handleClick("/register")}
                size="1"
                color="primary-1"
              >
                Start for free
              </PrimaryButton>
            </section>
          </section>
          <section className={classes.rightSection}>
            <img
              src="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceshero1.png"
              alt="hero"
              className={classes.heroImage}
            />
          </section>
        </section>
      </section>

      {/* <section className={classes.maxWidth}>
        <h2 className={classes.header}>
          Sync your inventory across all marketplaces
        </h2>

        <ul className={classes.marketplaces}>
          {platforms
            .filter((platform) => platform.name !== "CSV" && platform.available)
            .map((platform, index) => (
              <li className={classes.col} key={index}>
                <div key={index} className={classes.marketplace}>
                  <img
                    src={platform.img}
                    alt={platform.name}
                    className={classes.marketplaceIcon}
                  />
                </div>

                <p className={classes.marketplaceName}>{platform.name}</p>
              </li>
            ))}
        </ul>
      </section> */}
    </>
  );
};

export default Hero;
