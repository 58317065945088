import { useSelector } from "react-redux";
import classes from "./Footer.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const auth = useSelector((state) => state.auth);

  const history = useHistory();

  const handleClick = (link) => {
    history.push(link);
  };

  const mailTo = () => {
    window.location.href = "mailto: crosslist@newish.ai";
  };

  return (
    <footer className={classes.footer}>
      <section className={classes.footerContainer}>
        <section className={classes.topSection}>
          <section className={classes.left}>
            {auth?.isAuthenticated && (
              <div
                className={classes.link}
                onClick={() => handleClick("/dashboard")}
              >
                Dashboard
              </div>
            )}
            <div
              className={classes.link}
              onClick={() => handleClick("/marketplaces")}
            >
              Marketplaces
            </div>
            <div className={classes.link} onClick={() => handleClick("/")}>
              How It Works
            </div>
            <div className={classes.link} onClick={() => mailTo()}>
              Contact Us
            </div>
          </section>
          <section className={classes.right}>
            <section className={classes.col}>
              <div className={classes.colHeader}>Links</div>
              <section className={classes.colLinks}>
                <div
                  className={classes.link1}
                  onClick={() => handleClick("/marketplaces")}
                >
                  Marketplaces
                </div>
                <div className={classes.link1} onClick={() => handleClick("/")}>
                  How It Works
                </div>
                {auth?.isAuthenticated && (
                  <div
                    className={classes.link1}
                    onClick={() => handleClick("/dashboard")}
                  >
                    Dashboard
                  </div>
                )}
              </section>
            </section>
            <section className={classes.col}>
              <div className={classes.colHeader}>Company</div>
              <section className={classes.colLinks}>
                <div className={classes.link1} onClick={() => mailTo()}>
                  Contact
                </div>
                <div
                  className={classes.link1}
                  onClick={() => handleClick("/terms-and-conditions")}
                >
                  Terms
                </div>
                <div
                  className={classes.link1}
                  onClick={() => handleClick("/privacy-policy")}
                >
                  Privacy
                </div>
              </section>
            </section>
          </section>
        </section>
        <section className={classes.bottomSection}>
          <section className={classes.row}>
            <div className={classes.logoDiv} onClick={() => handleClick("/")}>
              <span className={classes.logoPart1}>New</span>
              <span className={classes.logoPart2}>ish </span>
            </div>

            <div className={classes.col1}>
              <section className={classes.logoText}>Newish by Kashew</section>
              <div className={classes.subText}>
                Your free furniture crosslister
              </div>
              <div className={classes.copyRight}>
                ©2024 Newish. All rights reserved.
              </div>
            </div>
          </section>
        </section>
      </section>
    </footer>
  );
};
export default Footer;
