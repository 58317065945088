import { GoMegaphone, GoPeople } from "react-icons/go";
import Benefits from "./Components/Benefits";
import FirstSection from "./Components/FirstSection";
import Hero from "./Components/Hero";
import classes from "./Marketplaces.module.css";
import { LuArmchair } from "react-icons/lu";
import { CiDeliveryTruck } from "react-icons/ci";
import { PiMoney } from "react-icons/pi";

const Etsy = () => {
  const heroInfo = {
    title: "Free Etsy Crosslister",
    subtitle: "How to sell furniture on Etsy? ",
    paragraph:
      "Etsy is a vibrant marketplace for unique and handmade furniture. Join thousands of small U.S. businesses and showcase your distinctive pieces to a vast audience of eager shoppers. Expand your reach and grow your furniture business.",
    logo: "https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceIcons/etsy.png",
  };

  const firstInfo = {
    title: "Why resellers need an Etsy shop",
    paragraphs: [
      "Over 7.5 million active sellers use Etsy, showcasing unique and handmade items to over 96 million active buyers worldwide.",
      "Recently Etsy has started to include vintage and antique items, making it a great place to sell your preloved furniture in the U.S.",
    ],
  };

  const gridItems = [
    {
      title: "Financial Benefits",
      paragraph:
        "With Etsy's competitive fee structure, you keep the majority of your earnings, maximizing your profits with every sale.",
      icon: <PiMoney className={classes.icon} />,
    },
    {
      title: "Marketing Tools",
      paragraph:
        "Etsy offers a variety of marketing tools, including promoted listings and social media integrations, to help you reach more buyers.",
      icon: <GoMegaphone className={classes.icon} />,
    },
    {
      title: "Optimized Listings",
      paragraph:
        "Etsy listings are optimized for SEO, meaning they can be discovered easily accross the web.",
      icon: <LuArmchair className={classes.icon} />,
    },
    {
      title: "Reach",
      paragraph:
        "Etsy connects you with a large community of U.S. buyers who appreciate unique furniture, exposing your listings to millions of potential customers.",
      icon: <GoPeople className={classes.icon} />,
    },
  ];

  return (
    <section className={classes.main}>
      <Hero
        title={heroInfo.title}
        subtitle={heroInfo.subtitle}
        paragraph={heroInfo.paragraph}
        logo={heroInfo.logo}
      />

      <section className={classes.maxWidth}>
        <FirstSection
          title={firstInfo.title}
          paragraphs={firstInfo.paragraphs}
          gridItems={gridItems}
        />
        <Benefits company="Etsy" />
      </section>
    </section>
  );
};
export default Etsy;
