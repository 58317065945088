import NavBar from "./Navbar";
import classes from "./Layout.module.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Alert from "./Alert";

const Layout = (props) => {
  const location = useLocation();
  const [color, setColor] = useState(classes.white);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname.includes("/marketplaces")
    ) {
      setColor(classes.white);
    } else {
      setColor(classes.grey);
    }
  }, [location.pathname]);

  return (
    <main className={`${classes.layout} ${color}`}>
      <section className={`${classes.navBar} ${color}`}>
        <NavBar />
      </section>
      <section className={classes.mainContent}>{props.children}</section>
      <section className={classes.footer}>
        <Footer />
      </section>
      <Alert />
    </main>
  );
};

export default Layout;
