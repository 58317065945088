import { Suspense, lazy, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PrimaryButton from "../UI/Buttons/PrimaryButton";
import classes from "./Navbar.module.css";
import ProfileModal from "./ProfileModal";
import { PiListFill } from "react-icons/pi";
import Loader from "../UI/Loader";

const MobileNavbarOverlay = lazy(() =>
  import("./Components/MobileNavbarOverlay")
);

const NavBar = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const profileRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);

  // Open modal when hovering over the profile button
  const handleMouseEnter = () => {
    setShow(true);
  };

  // Close modal when mouse leaves the profile button
  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <div className={classes.navBarContainer}>
        <div className={classes.leftHandSide}>
          <div
            className={classes.hamburger}
            onClick={() => setShowOverlay(true)}
          >
            <PiListFill className={classes.iconBurger} />
          </div>
          <div className={classes.logoDiv} onClick={() => history.push(`/`)}>
            <img
              src="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/LogoNewish.png"
              alt="newish logo"
              className={classes.logo}
            />
          </div>

          {!location.pathname.includes("dashboard") && (
            <button
              className={classes.nameButton}
              onClick={() => history.push(`/marketplaces`)}
            >
              Marketplaces
            </button>
          )}
        </div>
        <div className={classes.authActions}>
          {auth.isAuthenticated ? (
            <>
              {!location.pathname.includes("dashboard") && (
                <button
                  className={classes.nameButton}
                  onClick={() => history.push(`/dashboard/home`)}
                >
                  Dashboard
                </button>
              )}

              <section
                className={classes.profileButton}
                ref={profileRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h6
                  className={classes.name}
                  onClick={() => history.push(`/dashboard/home`)}
                >
                  {" "}
                  Hi {auth.user.name}!
                </h6>

                <div
                  className={classes.iconContainer}
                  onClick={() => history.push(`/dashboard/home`)}
                >
                  <img
                    src="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/icons/4.svg"
                    alt="profile"
                    className={classes.profile}
                  />
                </div>

                <ProfileModal
                  show={show}
                  setShow={setShow}
                  user={auth.user}
                  innerRef={profileRef}
                />
              </section>
            </>
          ) : (
            <>
              <div
                className={classes.authenticate}
                onClick={() => history.push(`/login`)}
              >
                Log in
              </div>
              <PrimaryButton onClick={() => history.push(`/register`)}>
                Join for free
              </PrimaryButton>
            </>
          )}
        </div>
      </div>

      <Suspense
        fallback={
          <img
            src="https://ik.imagekit.io/kuj9zqlrx0or/Static/Interwind-1s-200px.gif"
            style={{ width: "50px", height: "50px" }}
            alt="loader"
          />
        }
      >
        <MobileNavbarOverlay
          auth={auth}
          show={showOverlay}
          setShow={setShowOverlay}
        />
      </Suspense>
    </>
  );
};
export default NavBar;
