import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { platforms } from "../../../Utils/importPlatforms";
import PrimaryButton from "../../UI/Buttons/PrimaryButton";
import classes from "./MarketplacesList.module.css";

const MarketplacesList = () => {
  const history = useHistory();

  const handleClick = (link) => {
    history.push(link);
  };
  return (
    <>
      <section className={classes.maxWidth}>
        <h2 className={classes.header}>
          With Newish you can import and crosspost items on your favorite
          furniture marketplaces
        </h2>

        <ul className={classes.marketplaces}>
          {platforms
            .filter((platform) => platform.name !== "CSV")
            .sort((a, b) => b.available - a.available)
            .map((platform, index) => (
              <li
                className={classes.col}
                key={index}
                onClick={() =>
                  handleClick(
                    platform.available
                      ? `/marketplaces/${platform.name.toLowerCase()}`
                      : null
                  )
                }
              >
                <div
                  key={index}
                  className={`${classes.marketplace} ${
                    platform.available && classes.hover
                  }`}
                >
                  <div className={classes.iconContainer}>
                    <img
                      src={platform.img}
                      alt={platform.name}
                      className={
                        platform.available
                          ? classes.marketplaceIcon
                          : classes.marketplaceIconDisabled
                      }
                    />
                  </div>
                  <p className={classes.marketplaceName}>
                    {platform.name}{" "}
                    {!platform?.available && (
                      <span className={classes.comingSoon}>(Coming Soon)</span>
                    )}
                  </p>
                </div>
              </li>
            ))}
        </ul>

        <section className={classes.buttons}>
          <PrimaryButton
            onClick={() => handleClick("/register")}
            className={classes.button}
          >
            Start Crosslisting (It's Free)
          </PrimaryButton>
        </section>
      </section>
    </>
  );
};

export default MarketplacesList;
