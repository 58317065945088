import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const DashboardHelmet = () => {
  const location = useLocation();

  const generateTitle = () => {
    const path = location.pathname;
    switch (path) {
      case "/dashboard":
        return "Dashboard | Newish";
      case "/dashboard/inventory":
        return "Inventory | Newish";
      case "/dashboard/item":
        return "Create a product | Newish";
      case "/dashboard/accounts":
        return "Connected Marketplaces | Newish";
      case "/dashboard/settings":
        return "Settings | Newish";
      default:
        return "Dashboard | Newish";
    }
  };

  const generateDescription = () => {
    const path = location.pathname;
    switch (path) {
      case "/dashboard":
        return "Crosslist your inventory, manage your connected marketplaces, and customize your settings.";
      case "/dashboard/inventory":
        return "View and manage your inventory.";
      case "/dashboard/item":
        return "Create a product to crosslist on multiple marketplaces.";
      case "/dashboard/accounts":
        return "Connect and manage your marketplaces.";
      case "/dashboard/settings":
        return "Customize your settings.";
      default:
        return "Crosslist your inventory, manage your connected marketplaces, and customize your settings.";
    }
  };

  const ogUrl = () => {
    const path = location.pathname;
    switch (path) {
      case "/dashboard":
        return "https://www.newish.com/dashboard";
      case "/dashboard/inventory":
        return "https://www.newish.com/dashboard/inventory";
      case "/dashboard/accounts":
        return "https://www.newish.com/dashboard/accounts";
      case "/dashboard/item":
        return "https://www.newish.com/dashboard/item";
      case "/dashboard/settings":
        return "https://www.newish.com/dashboard/settings";
      default:
        return "https://www.newish.com/dashboard";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{generateTitle()}</title>
        <meta name="description" content={generateDescription()} />
        <meta property="og:title" content={generateTitle()} />
        <meta property="og:description" content={generateDescription()} />
        <meta property="og:url" content={ogUrl()} />
      </Helmet>
    </>
  );
};
export default DashboardHelmet;
