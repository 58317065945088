import classes from "./Legal.module.css";

const Terms = () => {
  return (
    <section className={classes.main}>
      <h1>Terms</h1>

      <p className={classes.lastUpdated}>Last Updated: 10/03/2024</p>

      <p>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and Newish, a
        part of Kashew Corp. ("Company", “we”, “us”, or “our”), concerning your
        access to and use of the newish.ai website as well as any other media
        form, media channel, mobile website, or mobile application related,
        linked, or otherwise connected thereto (collectively, the “Site”). You
        agree that by accessing the Site, you have read, understood, and agree
        to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL
        OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
        SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>

      <h2>Intellectual Property Rights</h2>
      <p>
        Unless otherwise indicated, the Site and all content and other materials
        therein, including, without limitation, the Newish logo, and all
        designs, text, graphics, pictures, information, data, software, sound
        files, other files, and the selection and arrangement thereof
        (collectively, "Content") are the proprietary property of the Company or
        our affiliates, licensors, or users, as applicable.
      </p>

      <h2>User Representations</h2>
      <ul>
        <li>
          By using the Site, you represent and warrant that all registration
          information you submit will be true, accurate, current, and complete.
        </li>
        <li>
          You have the legal capacity and you agree to comply with these Terms
          of Use.
        </li>
        <li>
          You will not access the Site through automated or non-human means,
          whether through a bot, script, or otherwise.
        </li>
        <li>
          Your use of the Site will not violate any applicable law or
          regulation.
        </li>
      </ul>

      <h2>Fees and Payment</h2>
      <p>
        We may charge you fees for certain products or services offered for sale
        through our Site. You agree to pay all fees charged to your account
        based on our fees, charges, and billing terms in effect as shown on the
        payment page you first sign-up for or use our Site.
      </p>

      <h2>User Generated Contributions</h2>
      <p>
        The Site may invite you to chat, contribute to, or participate in blogs,
        message boards, online forums, and other functionality and may provide
        you with the opportunity to create, submit, post, display, transmit,
        perform, publish, distribute, or broadcast content and materials to us
        or on the Site, including but not limited to text, writings, video,
        audio, photographs, graphics, comments, suggestions, or personal
        information or other material.
      </p>

      <h2>Contribution License</h2>
      <p>
        By posting your contributions to any part of the Site, you automatically
        grant, and you represent and warrant that you have the right to grant,
        to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully-paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), and
        distribute such contributions (including, without limitation, your image
        and voice) for any purpose, commercial, advertising, or otherwise, and
        to prepare derivative works of, or incorporate into other works, such
        contributions, and grant and authorize sublicenses of the foregoing.
      </p>

      <h2>Site Management</h2>
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Site
        for violations of these Terms of Use; (2) take appropriate legal action
        against anyone who, in our sole discretion, violates the law or these
        Terms; (3) manage the Site in a manner designed to protect our rights
        and property and to facilitate the proper functioning of the Site.
      </p>

      <h2>Term and Termination</h2>
      <p>
        These Terms of Use shall remain in full force and effect while you use
        the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE COMPANY SERVICES,
        TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
        LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
        CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions regarding these Terms of Use, please contact
        us at support@kashew.com.
      </p>
    </section>
  );
};
export default Terms;
