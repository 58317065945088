import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LOGOUT } from "../../Redux/Reducers/auth";
import classes from "./ProfileModal.module.css";
import { motion } from "framer-motion";

const ProfileModal = (props) => {
  const { show, innerRef } = props;
  const dispatch = useDispatch();

  const buttons = [
    { name: "Dashboard", link: "/dashboard/home" },
    { name: "Inventory", link: "/dashboard/inventory" },
    { name: "Connected accounts", link: "/dashboard/accounts" },
    { name: "Settings", link: "/dashboard/settings" },
  ];

  const history = useHistory();

  const logoutHandler = () => {
    dispatch(LOGOUT());
  };

  const list = {
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: {
      opacity: 0,
      height: 0,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: i * 0.1,
      },
    }),
    hidden: { opacity: 0 },
  };
  return (
    <>
      {/* {show && ( */}
      <motion.ul
        initial="hidden"
        animate={show ? "visible" : "hidden"}
        variants={list}
        className={classes.modal}
        ref={innerRef}
      >
        {buttons.map((button, index) => (
          <motion.li
            custom={index}
            animate={show ? "visible" : "hidden"}
            variants={variants}
            key={index}
            className={classes.button}
            onClick={() => history.push(button.link)}
          >
            {button.name}
          </motion.li>
        ))}
        <motion.li
          custom={4}
          animate={show ? "visible" : "hidden"}
          variants={variants}
          className={classes.buttonLogout}
          onClick={logoutHandler}
        >
          Logout
        </motion.li>
      </motion.ul>
      {/* )} */}
    </>
  );
};

export default ProfileModal;
