import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const DashboardHelmet = (props) => {
  const { marketplace } = props;

  const generateTitle = () => {
    switch (marketplace) {
      case "etsy":
        return "How To Sell Furniture On Etsy: The Ultimate Guide";
      case "ebay":
        return "How To Sell Furniture On eBay: The Ultimate Guide";
      case "chairish":
        return "How To Sell Furniture On Chairish: The Ultimate Guide";
      case "shopify":
        return "How To Sell Furniture On Shopify: The Ultimate Guide";
      case "kashew":
        return "How To Sell Furniture On Kashew: The Ultimate Guide";
      case "woocommerce":
        return "How To Sell Furniture On WooCommerce: The Ultimate Guide";
      default:
        return "How To Crosslist Furniture On All Marketplaces | Newish";
    }
  };

  const generateDescription = () => {
    switch (marketplace) {
      case "etsy":
        return "One Click Crosslising for Etsy. List your furniture on Etsy and other marketplaces in minutes.";

      case "ebay":
        return "One Click Crosslising for eBay. List your furniture on eBay and other marketplaces in minutes.";

      case "chairish":
        return "One Click Crosslising for Chairish. List your furniture on Chairish and other marketplaces in minutes.";

      case "shopify":
        return "One Click Crosslising for Shopify. List your furniture on Shopify and other marketplaces in minutes.";

      case "kashew":
        return "One Click Crosslising for Kashew. List your furniture on Kashew and other marketplaces in minutes.";

      case "woocommerce":
        return "One Click Crosslising for WooCommerce. List your furniture on WooCommerce and other marketplaces in minutes.";

      default:
        return "Crosslist your furniture on top marketplaces like Etsy, Craigslist, eBay, Chairish, AptDeco and Kashew with Newish. Manage all your listings in one place.";
    }
  };

  const ogUrl = () => {
    const path = window.location.pathname;
    const url = `https://www.newish.ai${path}`;
    return url;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <Helmet>
        <title>{generateTitle()}</title>
        <meta name="description" content={generateDescription()} />
        <meta property="og:title" content={generateTitle()} />
        <meta property="og:description" content={generateDescription()} />
        <meta property="og:url" content={ogUrl()} />
        <link rel="canonical" href={ogUrl()} />
      </Helmet>
    </>
  );
};
export default DashboardHelmet;
