import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inistialState = {
	loading: true,
	features: [],
	chairish: {
		brand_artists_designers_styledAfter: [],
		styles: [],
		materials: [],
		color: [],
		periods: [],
	},
	colors: [],
	brands: [],
	materials: [],
	categories: [],
	styles: [],
	rooms: [],
};

const schemas = createSlice({
	name: 'schemas',
	initialState: inistialState,
	reducers: {
		SCHEMAS_LOADING(state, action) {
			return (state = {
				...state,
				loading: true,
			});
		},
		SCHEMAS_LOADED(state, action) {
			console.log(action.payload);

			const {
				features,
				colors,
				brands,
				materials,
				categories,
				styles,
				rooms,
				chairish,
			} = action.payload;

			return (state = {
				chairish,
				features,
				colors,
				brands,
				materials,
				categories,
				styles,
				rooms,
				loading: false,
			});
		},
		SCHEMAS_ERROR(state, action) {
			return (state = {
				...state,
				loading: false,
			});
		},
	},
});

export const SCHEMAS_LOADED = schemas.actions.SCHEMAS_LOADED;
export const SCHEMAS_LOADING = schemas.actions.SCHEMAS_LOADING;
export const SCHEMAS_ERROR = schemas.actions.SCHEMAS_ERROR;

export const LOAD_SCHEMAS = () => async (dispatch) => {
	dispatch(SCHEMAS_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/connections/schemas`)
		.then((res) => {
			console.log(res.data);
			dispatch(SCHEMAS_LOADED(res.data));
		})
		.catch((err) => {
			console.log(err);
			dispatch(SCHEMAS_ERROR());
		});
};

export default schemas;
