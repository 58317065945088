import { Route, Switch } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react';
import SocketIO from 'socket.io-client';
import Layout from './components/Layout/Layout.js';
import ErrorBoundary from './Utils/ErrorBoundary.js';
import Loader from './components/UI/Loader.js';
import SomethingWentWrong from './components/UI/SomethingWentWrong.js';
import PrivateRoute from './Routing/PrivateRoute.js';
import { LOAD_USER, LOGIN_SUCCESS } from './Redux/Reducers/auth.js';
import { useDispatch, useSelector } from 'react-redux';
import MarketplacesPage from './Pages/MarketplacesPage.js';
import {
	LOAD_PRODUCTS,
	UPDATE_LOADING_PRODUCTS,
} from './Redux/Reducers/products.js';
import { LOAD_SCHEMAS } from './Redux/Reducers/schemas.js';
import {
	useHistory,
	useLocation,
} from 'react-router-dom/cjs/react-router-dom.min.js';
import axios from 'axios';
import NotFoundPage from './Pages/NotFoundPage.js';
import TermsOfUsePage from './Pages/TermsOfUsePage.js';
import PrivacyPage from './Pages/PrivacyPage.js';
import { Helmet } from 'react-helmet-async';
import DashboardHelmet from './Hooks/DashboardHelmet.js';
import EtsyRequestAuth from './Utils/EtsyRequestAuth.js';

const LandingPage = lazy(() => import('./Pages/LandingPage.js'));
const LoginPage = lazy(() => import('./Pages/LoginPage.js'));
const RegisterPage = lazy(() => import('./Pages/RegisterPage.js'));
const DashboardPage = lazy(() => import('./Pages/DashboardPage.js'));

// Heiti SC Medium

function App() {
	const dispatch = useDispatch();
	const history = useHistory();

	if (process.env.NODE_ENV === 'production') {
		console.log = function () {};
	}

	useEffect(() => {
		dispatch(LOAD_USER());
		dispatch(LOAD_PRODUCTS());
		dispatch(LOAD_SCHEMAS());
	}, []);

	const auth = useSelector((state) => state.auth);
	const products = useSelector((state) => state.products);
	const location = useLocation();

	const [loading, setLoading] = useState();
	const getKashewUser = async (id, token) => {
		if (loading) return;

		setLoading(true);

		await axios
			.get(
				`${
					process.env.NODE_ENV === 'production'
						? process.env.REACT_APP_KASHEW_SERVER_API
						: process.env.REACT_APP_KASHEW_DEV_API
				}/api/api/auth/${id}`,
				{
					headers: {
						'x-auth-token': token,
					},
				}
			)
			.then(async (reponse) => {
				await axios
					.post(
						`${process.env.REACT_APP_SERVER_API}/api/auth/kashew-registration`,
						{
							user: reponse.data,
						}
					)
					.then((res) => {
						console.log(res.data);
						dispatch(LOGIN_SUCCESS(res.data));
						history.push(`/dashboard`);
					});
			})
			.catch((err) => {
				console.log(err);
			});
		setLoading(false);
	};

	useEffect(() => {
		if (!auth.isAuthenticated) {
			const params = new URLSearchParams(location.search);
			const kashew = params.get('kashewId');
			const token = params.get('token');

			if (kashew && token && !loading) {
				getKashewUser(kashew, token);
			}
		}
	}, [auth, location]);

	const [socket, setSocket] = useState(null);

	useEffect(() => {
		if (!socket) {
			let newSocket = SocketIO(process.env.REACT_APP_SERVER_API, {
				withCredentials: true,
				transports: ['websocket', 'polling'],
			});

			setSocket(newSocket);
			return () => {
				newSocket.disconnect();
				socket?.disconnect();
			};
		}
	}, []);

	useEffect(() => {
		if (socket && auth.isAuthenticated) {
			socket.on('product-edit', ({ type, product, user, loading }) => {
				console.log('socket ', { type, product, user, loading });
				if (user === auth.user._id) {
					if (
						products.loadingProducts?.find(
							(i) => i.product == product && i.type == type
						) &&
						!loading
					) {
						console.log('FILTER out');
						dispatch(
							UPDATE_LOADING_PRODUCTS(
								products.loadingProducts.filter((i) => i.product !== product)
							)
						);
					} else if (
						!products.loadingProducts?.find(
							(i) => i.product == product && i.type == type
						) &&
						loading
					) {
						dispatch(
							UPDATE_LOADING_PRODUCTS([
								...products.loadingProducts,
								{ product, type },
							])
						);

						setTimeout(function () {
							dispatch(
								UPDATE_LOADING_PRODUCTS(
									products.loadingProducts.filter((i) => i.product === product)
								)
							);
						}, 5000);
					}
				}
			});
		}
	}, [socket, auth]);

	return (
		<>
			<Suspense fallback={<Loader />}>
				<ErrorBoundary fallback={<SomethingWentWrong />}>
					<Layout>
						<EtsyRequestAuth />
						<Switch>
							<Route exact path="/">
								<LandingPage />
								<Helmet>
									<title>Newish - 100% Free Furniture Crosslister</title>
									<meta
										name="description"
										content="100% free furniture crosslister for Etsy, Craigslist, Ebay, and more. List your items in seconds and manage them all in one place."
									/>
									<meta
										property="og:title"
										content="Newish - 100% Free Furniture Crosslister"
									/>
									<meta
										property="og:description"
										content="100% free furniture crosslister for Etsy, Craigslist, Ebay, and more. List your items in seconds and manage them all in one place."
									/>
									<meta
										property="og:image"
										content="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceshero1.png"
									/>
									<meta property="og:url" content="https://newish.ai" />
									<meta property="og:type" content="website" />
									<link
										rel="canonical"
										href="https://www.newish.ai/privacy-policy"
										data-rh="true"
									/>
								</Helmet>
							</Route>

							<Route exact path="/marketplaces/:marketplace?">
								<MarketplacesPage />
							</Route>

							<Route exact path="/login">
								<LoginPage />
								<Helmet>
									<title>Login | Newish</title>
									<meta
										name="description"
										content="Login to your Newish account to manage your furniture listings across multiple marketplaces easily."
									/>
									<meta
										property="og:title"
										content="Login to Your Account | Newish"
									/>
									<meta
										property="og:description"
										content="Access your Newish dashboard to start crosslisting your furniture on top marketplaces."
									/>
									<meta
										property="og:image"
										content="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceshero1.png"
									/>
									<meta property="og:url" content="https://newish.ai/login" />
									<meta property="og:type" content="website" />
									<link rel="canonical" href="https://www.newish.ai/login" />
								</Helmet>
							</Route>
							<Route exact path="/register">
								<RegisterPage />
								<Helmet>
									<title>Register | Newish</title>
									<meta
										name="description"
										content="Create a Newish account today and start crosslisting your furniture on multiple marketplaces effortlessly."
									/>
									<meta
										property="og:title"
										content="Register for Newish | Start Crosslisting Today"
									/>
									<meta
										property="og:description"
										content="Sign up now to manage and crosslist your furniture listings across various marketplaces with ease."
									/>
									<meta
										property="og:image"
										content="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceshero1.png"
									/>
									<meta
										property="og:url"
										content="https://newish.ai/register"
									/>
									<meta property="og:type" content="website" />
									<link
										rel="canonical"
										href="https://www.newish.ai/register"
										data-rh="true"
									/>
								</Helmet>
							</Route>
							<Route exact path="/terms-and-conditions">
								<TermsOfUsePage />
								<Helmet>
									<title>Terms and Conditions | Newish</title>
									<meta
										name="description"
										content="Read the terms and conditions for using Newish, your go-to furniture crosslisting platform."
									/>
									<link
										rel="canonical"
										href="https://www.newish.ai/terms-and-conditions"
										data-rh="true"
									/>
								</Helmet>
							</Route>
							<Route exact path="/privacy-policy">
								<PrivacyPage />
								<Helmet>
									<title>Privacy Policy | Newish</title>

									<meta
										name="description"
										content="Understand how Newish protects and uses your data. Privacy and transparency are our top priorities."
									/>
									<link
										rel="canonical"
										href="https://www.newish.ai/privacy-policy"
										data-rh="true"
									/>
								</Helmet>
							</Route>

							<PrivateRoute exact path="/dashboard/:id?">
								<DashboardPage />
								<DashboardHelmet />
							</PrivateRoute>
							<Route path="*">
								<NotFoundPage />
								<Helmet>
									<title>Page Not Found | Newish</title>
									<meta
										name="description"
										content="The page you're looking for doesn't exist. Check the URL or return home to continue exploring Newish."
									/>
									<meta property="og:title" content="Page Not Found | Newish" />
									<meta
										property="og:description"
										content="Oops! The page you tried to reach does not exist. Explore Newish to find the best way to list and sell your furniture."
									/>
									<meta
										property="og:image"
										content="https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/marketplaceshero1.png"
									/>
									<meta property="og:url" content="https://newish.ai/404" />
									<meta property="og:type" content="website" />
								</Helmet>
							</Route>
						</Switch>
					</Layout>
				</ErrorBoundary>
			</Suspense>
		</>
	);
}

export default App;
