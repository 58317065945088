import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import classes from './EtsyRequestAuth.module.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../components/UI/Buttons/PrimaryButton';

const EtsyRequestAuth = () => {
	const [connection, setConnection] = useState();

	const auth = useSelector((state) => state.auth);

	const [requestEtsyAuth, setRequestEtsyAuth] = useState(false);

	useEffect(() => {
		if (connection?.etsy?.requestAuth) {
			setRequestEtsyAuth(true);
		}
	}, [connection]);

	useEffect(() => {
		if (auth.isAuthenticated) {
			let found = auth.user.accounts.find((i) => i.type === 'Etsy');
			if (found) setConnection(found);
		}
	}, [auth]);

	console.log(connection);

	const [loadingUpdateEtsyTokens, setLoadingUpdateEtsyTokens] = useState(false);

	const handleUpdateEtsyTokens = async () => {
		if (loadingUpdateEtsyTokens) return;
		setLoadingUpdateEtsyTokens(true);
		await axios
			.get(`${process.env.REACT_APP_SERVER_API}/api/etsy/update-store-tokens`)
			.then((res) => {
				window.open(res.data, '_blank');
			})
			.catch((err) => {
				console.log(err);
			});
		setLoadingUpdateEtsyTokens(false);
	};

	return (
		<>
			<Modal show={requestEtsyAuth} onHide={() => setRequestEtsyAuth(false)}>
				<>
					<Modal.Header closeButton>
						Your Etsy connection has expired
					</Modal.Header>
					<div className={classes.modalBody}>
						Please log in on Etsy again to re-connect your Etsy account. (Your
						account may get disconnected after a period of inactivity)
					</div>
					<PrimaryButton size="size-2" onClick={handleUpdateEtsyTokens}>
						{loadingUpdateEtsyTokens ? 'Loading...' : 'Log into Etsy'}
					</PrimaryButton>
				</>
			</Modal>
		</>
	);
};
export default EtsyRequestAuth;
